import "./Press.css";
import { Link, NavLink } from "react-router-dom";

const PressIt = () => {
  return (
    <div className="container">
      <div className="row" id="main-title">
        <h1>Press Release</h1>
      </div>
      <div className="row languages">
        <h6>
          <button>
            <Link to="/press">EN</Link>
          </button>
          |
          <button id="IT">
            <NavLink to="/press-fr">FR</NavLink>
          </button>
          |
          <button>
            <NavLink to="/press-de">DE</NavLink>
          </button>
          |
          <button>
            <NavLink to="/press-pol">POL</NavLink>
          </button>
          |
          <button>
            <NavLink to="/press-ro">RO</NavLink>
          </button>
        </h6>
      </div>

      <div className="row" id="press">
        <h1>
          Piattaforma di assistenza integrata basata sul monitoraggio della
          capacità intrinseca degli anziani per la salute inclusiva (CAREUP)
        </h1>
        <h6>Comunicato stampa/ 30 giugno 2022</h6>
        <p>
          Il progetto CAREUP, coordinato da ECLEXYS Sagl, indaga su come
          implementare nel migliore modo possibile la strategia “inclusive
          health & care approach” (prevenzione e mantenimento della salute
          fisica e mentale, partecipazione sociale)” così come espressa nel
          “Bando AAL 2021”. Al centro del progetto sono poste le specifiche
          esigenze degli utenti finali (primari, secondari e terziari). Gli
          utenti primari (anziani con una età superiore ai 65 anni) sono
          individui che tendono a lamentare un calo dei livelli di energia
          fisica (capacità fisico cognitiva, perdita di capacità muscolare,
          ecc.) anche in assenza di una malattia clinicamente evidente. Ipotesi
          del progetto è che l’emergere di tali aspetti sia facilitato anche
          dalla mancanza di un adeguato approccio di “life coach”. Il progetto
          CAREUP propone una soluzione “ICT-based” che mira a migliorare la
          qualità della vita degli anziani nel corso dell’evolversi della
          propria condizione in accordo con i concetti di “Positive Health” (PH)
          e di “Healthy Ageing” (HA) espresso dall’OMS. La soluzione proposta da
          CAREUP consente agli anziani di seguire le strategie PH/HA tramite un
          monitoraggio autonomo dei loro elementi chiave: Abilità Funzionale
          (FA) e Capacità Intrinseca (IC). La FA comprende l'IC dell'individuo e
          include tutte le capacità mentali, sociali, cognitive e fisiche a cui
          una persona può attingere, comprese la sua capacità di camminare,
          pensare, sentire, interagire, riflettere, vedere, ascoltare e
          ricordare.
        </p>
        <br />
        <p>
          Per raggiungere quanto sopra, il progetto propone un approccio
          centrato sulla persona basato sulla progettazione ed implementazione
          di una piattaforma ICT in grado di monitorare l'IC e l'AF degli
          individui in relazione ai loro ambienti specifici. Tramite lo sviluppo
          della piattaforma CAREUP, obiettivo è quello di implementare un piano
          assistenziale personalizzato e con interventi multicomponenti volti a
          compensare i decadimenti fisico-cognitivi in cui, causa naturale
          invecchiamento, gli anziani rischiano di cadere. La piattaforma CAREUP
          non richiede quasi nessuna azione da parte dell'utente. L'interazione
          umana è combinata con la tecnologia IT.
        </p>
        <br />
        <p>
          La piattaforma sarà sviluppata a costi contenuti ed è aperta a
          estensioni con nuovi sensori, questionari e giochi. Per gli utenti
          finali secondari (caregiver formali e informali), CAREUP si
          confronterà con l'esigenza di implementare il modello “right/global
          care provision” che mira a supportare un approccio assistenziale
          integrato all'intero corso della vita della persona e a spostare
          l'attenzione dalla cura della malattia al miglioramento dell’IC nel
          corso della vita. Il problema principale per gli utenti finali
          secondari sarà il tempo e lo sforzo necessari per installare e
          mantenere la piattaforma e addestrare gli utenti finali a utilizzarla.
          Per fare ciò la piattaforma è co-creata insieme agli utenti primari e
          secondari, informali e formali (medici, assistenti sociali,
          psicologi...) per realizzare la nostra “unique selling proposition”.
          Per gli utenti finali terziari (stakeholder), la soluzione CAREUP
          soddisferà la loro esigenza di promuovere un ecosistema di
          invecchiamento sano che includa un'assistenza sanitaria a prezzi
          accessibili e opportunità commerciali e con un buon rapporto
          costo-efficacia delle soluzioni proposte.
        </p>
        <p>
          Infine, i partner del consorzio del progetto CAREUP (link to the
          consortium) sono ben equilibrati sia in termini di diffusione
          geografica 10 partner provenienti da 5 paesi. Il consorzio è composto
          da tre categorie di partner, un'università e un centro R&D, un utente
          finale e R&S, due utenti finali e 5 PMI.
        </p>
        <p>
          Per ulteriori informazioni si prega di visitare (
          <Link to="/">CAREUP</Link>)
        </p>
      </div>
    </div>
  );
};

export default PressIt;
