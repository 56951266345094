import { NavLink } from "react-router-dom";
import "./Blogs.css";
import art9_image from "../../../static/img/art9.png";
const Blog9 = () => {
  return (    
      <div className="container  ">
        <div className="row blogs" id="main-title">
          <NavLink to="/blogs">
            <h1>Blogs</h1>
          </NavLink>
        </div>
        <div className="row full_blogs">
          <h4>
            Challenges for Assistive Technologies That Target Older Adults:
            Using Examples from AAL Funded Projects and the wider literature
          </h4>
          <p class="card-text">
            <small className="text-muted">Published on 20 September 2023</small>
          </p>
          <p>
            Dementia cases are projected to increase to 152 million by 2050,
            posing a challenge to healthcare systems (Nichols et al., 2022). The demand for dementia
            diagnosis will surge with the availability of new drugs, potentially
            overwhelming healthcare systems. Digital cognitive assessment is
            seen as a solution, offering advantages in accessibility,
            measurement richness, and cost-efficiency. It allows for instant
            automated scoring, standardised administration, and enhanced
            stimulus presentation. However, this technology is still in its
            early stages and faces various challenges.
          </p>

          <img src={art9_image} className="agtech" alt="..." />
          <br />
          <p>Some of the challenges of digital assessment:</p>

          <ul>
            <li>
              <b>the testing environment</b> - self-administration without examiner
              supervision, may not consistently produce valid and reliable
              results (Binng et al., 2021);
            </li>

            <li>
              <b>the type of device being used</b> -- accuracy and precision of
              measurements vary depending on the hardware and software (Germine et al., 2019);
            </li>
            <li>
            <b>the function/domain that is being assessed</b> -- the translation
              process can sometimes substantially threaten tasks validity (Bailey et al., 2017).
            </li>
          </ul>
          <p>
            Data from 8304 participants, aged 18–35, using TestMyBrain.org,
            shows that part A of the trail-making test is completed 24–30%
            faster on an iPad compared to a Macintosh computer, while taking
            30–31% longer on an iPhone (Germine et al., 2019).
          </p>
          <p>
            The NIH Toolbox, a concise assessment tool for cognition, senses,
            motor skills, and emotions, originated in 2004. It was funded by NIH
            Blueprint for Neuroscience and shaped by input from 300 scientists
            across 100 institutions. Initially designed for computers, it
            transitioned to iPad administration. After over a year, disparities
            in norms for the iPad version emerged, resulting in adjusted norms
            released two months later (Brealy et al., 2019).
          </p>
          <p>
            Associated devices permit simultaneous collection of primary and
            secondary data this enables the storing of valuable behavioural data
            that is typically not available in traditional cognitive assessment (Vijayan et al., 2021).
          </p>
          <p>
            Still, we must determine if auxiliary devices reliably measure the
            construct under investigation. In our experience, this isn't always
            confirmed. For example, a device for measuring grip strength, from
            one of our (Ana Aslan International Foundation - AAIF) projects that
            aimed to develop a digital frailty monitoring system, had not been
            empirically evaluated, leaving uncertainty about its accuracy and
            reliability in grip strength measurement. We (AAIF) had to run a
            separate pilot study to assess the inter-instrument reliability and
            concurrent validity between said device (the Squegg® Smart
            Dynamometer and Hand Grip Trainer) and the gold standard tool for
            grip strength measurement (Jamar® Hydraulic Hand Dynamometer). The
            study will be published this October in the American Journal of
            Occupational Therapy; Volume: 77; Issue: 5.
          </p>
          <p>
            To conclude, we need to think about what (test/scale) we are
            digitalising, where they will be used (lab/home), how they will be
            delivered (what medium), the reliability/validity of associated
            devices.
          </p>

          <div id="article-references">
            <h6>References:</h6>
            <ul>
              <li>
              Bailey, S. K., Neigel, A. R., Dhanani, L. Y., & Sims, V. K. (2017). Establishing measurement equivalence across computer- and paper-based tests of spatial cognition. Human Factors, 60, 340–350  
                . <a href="https://doi.org/10.1177/0018720817747731" target="_blank">
                https://doi.org/10.1177/0018720817747731
                </a>.
              </li>
              <li>
                Binng, D., Splonskowski, M., & Jacova, C. (2021). Distance assessment for detecting cognitive impairment in older adults: a systematic review of psychometric evidence. Dementia and Geriatric Cognitive Disorders, 49(5), 456-470
                . <a href="https://doi.org/10.1159/000511945" target="_blank">
                https://doi.org/10.1159/000511945
                </a>.
              </li>
              <li>
                Brearly, T. W., Rowland, J. A., Martindale, S. L., Shura, R. D., Curry, D., & Taber, K. H. (2018). Comparability of iPad and web-based NIH Toolbox Cognitive Battery administration in Veterans. Archives of Clinical Neuropsychology, 34(4), 524-530                
                . <a href="https://doi.org/10.1093/arclin/acy070" target="_blank">
                https://doi.org/10.1093/arclin/acy070
                </a>.
              </li>
              <li>
                Germine, L., Reinecke, K., & Chaytor, N. S. (2019). Digital neuropsychology: Challenges and opportunities at the intersection of science and software. The Clinical Neuropsychologist, 33(2), 271-286
                . <a href="https://doi.org/10.1080/13854046.2018.1535662" target="_blank">
                https://doi.org/10.1080/13854046.2018.1535662
                </a>.
              </li>
              <li>
                Nichols, E., Steinmetz, J. D., Vollset, S. E., Fukutaki, K., Chalek, J., Abd-Allah, F., ... & Liu, X. (2022). Estimation of the global prevalence of dementia in 2019 and forecasted prevalence in 2050: an analysis for the Global Burden of Disease Study 2019. The Lancet Public Health, 7(2), e105-e125
                . <a href="https://doi.org/10.1016/S2468-2667(21)00249-8" target="_blank">
                https://doi.org/10.1016/S2468-2667(21)00249-8
                </a>.
              </li>
              <li>
                Vijayan, V., Connolly, J. P., Condell, J., McKelvey, N., & Gardiner, P. (2021). Review of wearable devices and data collection considerations for connected health. Sensors, 21(16), 5589.                
              </li>
            </ul>
          </div>

        </div>
      </div>    
  );
};

export default Blog9;
