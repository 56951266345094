import "./Profiles.css";

const Inrca = () => {
  return (
    <div className="container">
      <div className="row" id="main-title">
        <h1>National Institute of Health and Science on Aging- INRCA</h1>
      </div>
      <div className="row" id="partner-intro">
        <div className="col">
          <p id="partnerInroduction">
            INRCA (IT) is the Italian leading public Institute in gerontology
            and geriatrics, devoted to improve quality of life of older persons.
            The objectives of the Institute are focused on successful ageing and
            the promotion of health of the older person and prevention. INRCA
            pursues its goal mainly in an interdisciplinary way, through
            clinical and translational research, training in the bio-medical
            field as well as in the organization and management of health care
            services, in particular by means of highly specialized
            hospitalization and health care. The national dimension of INRCA and
            its international network of contacts represent an “added value” and
            a point of reference in the study of ageing and of long-term care
            related issues, as recently confirmed by the fact that INRCA has
            been recently acknowledged as the coordinator of the National
            Longevity Network on behalf of the Italian Ministry of Health. The
            institute has gained substantial experience in large scale European
            and international projects on ageing. INRCA has participated in
            several European projects. To know more about us visit our{" "}
            <a href="https://www.inrca.it/inrca/?lingua=en" target="_blank" rel="noreferrer">
              website
            </a>
            .
          </p>
        </div>
      </div>
      <div className="row">
        <h2 id="projectRole">Project Role</h2>
      </div>

      <div className="row" id="projectRoleDetails">
        <div className="col">
          <p id="projectRoleIntroduction">
            WP3 leader, participant in other WPs activities that require end-user 
            involvement, user centered design and IC competences, dissemination.
          </p>
        </div>
      </div>
      <div className="row">
        <h2 id="projectRelatedExpertise">Related Expertise </h2>
      </div>

      <div className="row" id="projectRelatedExpertiseDetails">
        <div className="col">
          <p id="projectRelatedExpertiseIntroduction">
            Research, end-user involvement, definition of methodological
            approach, user centered design expert, intrinsic capacity expert
          </p>
        </div>
      </div>

      <div className="row">
        <h2 id="teamMembers">Team Members</h2>
      </div>
      <div className="row">
        <div className="col" id="teamMemberDetails">
          <h5 id="teamMemberName">Vera Stara</h5>
          <p id="memberIntroduction">
            She received a PhD in Artificial Intelligence Systems from the
            University of Ancona in 2006 and a professional continuing education
            certificate as instructional designer for e-learning system from the
            University of Cagliari in 2005. Since 2010 she is a researcher at
            the National Institute of Health and Science on Aging - I.N.R.C.A.
            in the field of User Centered Design and Human Computer Interaction.
            Her main research focus are on human factors approaches to aging and
            technology use under the framework of national/international
            projects on ageing.
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col" id="teamMemberDetails">
          <h5 id="teamMemberName">Margherita Rampioni</h5>
          <p id="memberIntroduction">
            After the degree in Dynamic and Clinical Psychology of Person,
            Organizations and Communities at the University of Rome “La
            Sapienza”, completed her specialization in Cognitive Behavioral
            Psychotherapy in 2015. She received her PhD, Doctor Europaeus, in
            Psychology, Communication and Social Sciences from the University of
            Macerata in 2019. In 2021, she holds the Master's degree in Clinical
            Neuropsychology of the LUMSA Master School in Rome. She currently
            collaborates with the National Institute of Health and Science on
            Aging I.N.R.C.A. in the field of User Centered Design and Human
            Computer Interaction. Her main research interest is on human factors
            approaches to aging and technology use.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Inrca;
