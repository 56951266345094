import { NavLink } from "react-router-dom";
import "./Blogs.css";
import art18_image from "../../../static/img/art18.jpg";

const Blog18 = () => {
  return (
      <div className="container  ">
        <div className="row blogs" id="main-title">
          <NavLink to="/blogs">
            <h1>Blogs</h1>
          </NavLink>
        </div>
        <div className="row full_blogs">
          <h4>
            Dependent living and caregiver burden
          </h4>
          <p class="card-text">
            <small className="text-muted">Published on 19 April 2024</small>
          </p>
          <img src={art18_image} className="agtech" alt="..." />
          <p align="center">(source: Everyday Health, Caregiver Burnout: What It Is, Signs You’re Experiencing It, and How to Cope
            &nbsp;<a href="https://www.everydayhealth.com/burnout/caregiver-burnout/" target="_blank" rel="noreferrer">
            https://www.everydayhealth.com/burnout/caregiver-burnout/</a>)</p>          
          <br />
          <p>
            The average life expectancy at birth has, and will continue to, increase in the modern era. According to the United Nations, 
            the population over the age of 65 in Europe has more than tripled since 1950, reaching approximately 143 million people in 2019 
            (United Nations, 2019). To put that in percentages, the current number of seniors in Europe is 226% higher than in 1950.
          </p>          
          <p>
            The problem faced by the contemporary society is the difficulty, verging on impossibility sometimes, of the elderly individuals 
            to manage life on their own, and to lead independent lives. If we consider that, before the industrial era, families were organized in 
            an extended manner, meaning that several generations could share the same house, now this practice is rarely encountered, either in 
            the rural areas, or in exceptional situations. The preferred form of family organization today, especially in the urban environment, 
            is nuclear, consisting of 2 partners and, where it applies, their children.
          </p>
          <p>
            This circumstance leaves little room for grandparents, or elderly parents, who often find themselves in a situation where their health 
            does not allow them to be independent, while their children do not have the necessary time or conditions needed for full time tending. 
            The solutions offered by the state or private sector in these situations could merit some consideration: assisted living facilities, retirement 
            communities or villages and nursing homes are but a part of the wide array of available options. However, these solutions usually require 
            a good financial situation and a degree of proximity, so that family can visit often and keep in touch with the formal caregivers and 
            medical personnel.
          </p>
          <p>
            Independent living can also be a solution, but what could be done when the senior is no longer capable of it? A situation where an adult is 
            required to take care of their elderly relative due to health or psychological issues, and is not unable to do so, can lead to feelings of guilt, 
            anxiety, irritability, tiredness and even illness. The stress alone, if left unchecked, can take a severe toll on the caregiver’s life.
          </p>
          <p>
            Taking a closer look at the effects that the burden has on caregivers, they can be clasified as medical, psychological, and social. From a 
            medical perspective, it can lead to several health issues, through high levels of colesterol and cortisol that lead to chronic conditions or 
            exacerbate them, such as heart disease, arthritis, and diabetes (Colvin & Bullock, 2016). Psychological or mental problems are also quite 
            frequent among informal caregivers: symptoms of depression and anxiety, which manifest through decreased motivation; difficulty in concentration 
            and tolerating distress; sleep and appetite disturbances; as well as an increase in agitation and irritability (Bevans & Sternberg, 2012). 
            From a social perspective, caregiving requires a substantial amount of time, which can lead to neglecting friends and family, reduced or lack of 
            participation in social events and meetings, or even social isolation. Simultaneously, caregiving can interfere with activities meant to increase 
            financial wellbeing, and lead to economic burden.
          </p>
          <p>
            More than 12 million people care for, or provide help to their partner or relatives that are sick, elderly or disabled in the European Union. 
            This is mainly performed by women, aged 45 years and above, with medium educational level, and without children in the household. The caregivers 
            tend to live more in towns, suburbs or other rural areas, instead of cities. Furthermore, the majority of people taking care of incapacitated 
            relatives have neither reduced their working time, nor had a career break of more than one month in order to reconcile work and 
            care responsibilities (Eurostat, 2018).
          </p>
          <p>
            It is imperative for the EU and the scientific community to search for ways to significantly reduce the burden of caregivers and increase the ability 
            of senior citizens to live independently. The CAREUP project aims to do just that, by designing and implementing a platform based on information and 
            communication technologies (ICT) which is able to monitor the ability to be independant and the intrinsic capacity of individuals, in relation to their 
            specific environments, so as to compensate for the decline that comes with age.
          </p>
          <p></p>
          <div id="article-references">
            <h5><i>References:</i></h5>            
              <li>
              Bevans, M., & Sternberg, E. M. (2012). Caregiving burden, stress, and health effects among family caregivers of adult cancer patients. 
              JAMA, 307(4), 398–403. doi:10.1001/jama.2012.29              
              </li>
              <li>
              Colvin, A. D., & Bullock, A. N. (2016). A review of the biopsychosocial aspects of caregiving for aging family members. Journal of Family Social Work, 19(5), 420– 442. 
              doi:10.1080/10522158.2016.1214657              
              </li>
              <li>
                Eurostat (2018). Statistics Explained. Available at:
                &nbsp;<a href="https://ec.europa.eu/eurostat/statistics-explained/index.php?title=Reconciliation_of_work_and_family_life_-_statistics" target="_blank" rel="noreferrer">
                https://ec.europa.eu/eurostat/statistics-explained/index.php?title=Reconciliation_of_work_and_family_life_-_statistics</a>
                &nbsp; (Accessed: 05 April 2024).
              </li>              
               <li>
                United Nations – Department of Economic and Social Affairs, Population Division (2019). World Population Prospects 2019, Volume II: Demographic Profiles (ST/ESA/SER.A/427). Available at:
                &nbsp;<a href="https://population.un.org/wpp/Graphs/1_Demographic%20Profiles/Europe.pdf " target="_blank" rel="noreferrer">
                https://population.un.org/wpp/Graphs/1_Demographic%20Profiles/Europe.pdf</a>
                &nbsp; (Accessed: 05 April 2024).
              </li>              
            <p></p>
          </div>         
        </div>
      </div>
  );
};

export default Blog18;
