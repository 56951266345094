import { NavLink } from "react-router-dom";
import "./Blogs.css";
import art1_image from "../../../static/img/art1.jpeg";
import art3_image from "../../../static/img/art3.jpg";
import art4_image from "../../../static/img/art4.jpg";
import art5_image from "../../../static/img/art5.jpg";
import art6_image from "../../../static/img/art6.jpg";
import art7_image from "../../../static/img/art7.jpeg";
import art8_image from "../../../static/img/art8.png";
import art9_image from "../../../static/img/art9.png";
import art10_image from "../../../static/img/art10.png";
import art11_image from "../../../static/img/art11.png";
import art12_image from "../../../static/img/art12.jpg";
import art13_image from "../../../static/img/art13.png";
import art14_image from "../../../static/img/art14.png";
import art15_image from "../../../static/img/art15.jpg";
import art16_image from "../../../static/img/art16.jpg";
import art17_image from "../../../static/img/art17.png";
import art18_image from "../../../static/img/art18.jpg";
import art19_image from "../../../static/img/art19.png";
import art20_image from "../../../static/img/art20.jpg";
import art21_image from "../../../static/img/art21.jpg";
import art22_image from "../../../static/img/art22.png";
import art23_image from "../../../static/img/art23.png";
import consortium_image from "../../../static/img/consortium.png";

const Blogs = () => {
  return (
    <div className="container ">
      <div className="row" id="main-title">
        <h1>Blog posts</h1>
      </div>
      <div className="row  row-cols-1 row-cols-sm-1 row-cols-md-3 blogs">
        <NavLink to="/blog1">
          <div className="col">
            <div className="card">
              <img src={consortium_image} className="card-img-top" alt="..." />
              <div className="card-body">
                <h5 className="card-title">
                  Integrated Care Platform Based on the Monitoring of Older
                  Individual Intrinsic Capacity for Inclusive Health (CAREUP)
                </h5>
                <p class="card-text">
                  <small className="text-muted">
                    Published on 28 September 2022
                  </small>
                </p>

                <p className="card-text">
                  The CAREUP project investigates in the   implementation of the
                  “inclusive health & care approach ...
                </p>
              </div>
            </div>
          </div>
        </NavLink>

        <NavLink to="/blog2">
          <div className="col">
            <div className="card">
              <img src={art1_image} className="card-img-top" alt="..." />
              <div className="card-body">
                <h5 className="card-title">
                  Usage of technical solutions in daily life – how to deal with
                  privacy, confidentiality and data protection
                </h5>
                <p class="card-text">
                  <small className="text-muted">
                    Published on 03 November 2022
                  </small>
                </p>
                <p className="card-text">
                  In view of demographic change and the competition for
                  employees in the care sector..
                </p>
              </div>
            </div>
          </div>
        </NavLink>

        <NavLink to="/blog3">
          <div className="col">
            <div className="card">
              <img src={art3_image} className="card-img-top" alt="..." />
              <div className="card-body">
                <h5 className="card-title">
                  Positive Health, Intrinsic Capacity, and CAREUP system
                </h5>
                <p class="card-text">
                  <small className="text-muted">
                    Published on 21 December 2022
                  </small>
                </p>
                <p className="card-text">
                  Population ageing is a global trend. Older people who are
                  facing this stage of life, especially those with physical and
                  cognitive problems related to ..
                </p>
              </div>
            </div>
          </div>
        </NavLink>
      </div>

      <div className="row row-cols-1 row-cols-sm-1 row-cols-md-3 blogs">
        <NavLink to="/blog4">
          <div className="col">
            <div className="card">
              <img src={art4_image} className="card-img-top" alt="..." />
              <div className="card-body">
                <h5 className="card-title">
                  Benefits of Digital Games for Seniors
                </h5>
                <p class="card-text">
                  <small className="text-muted">
                    Published on 17 January 2023
                  </small>
                </p>
                <p className="card-text">
                  It is an acknowledged fact that there are several barriers
                  when it comes to seniors and ..
                </p>
              </div>
            </div>
          </div>
        </NavLink>

        <NavLink to="/blog5">
          <div className="col">
            <div className="card">
              <img src={art5_image} className="card-img-top" alt="..." />
              <div className="card-body">
                <h5 className="card-title">
                  CAREUP as a tool for elderly people assistance in the frame of
                  revised EU pandemic plans
                </h5>
                <p class="card-text">
                  <small className="text-muted">
                    Published on 25 February 2023
                  </small>
                </p>
                <p className="card-text">
                  Although the COVID-19 pandemic seems to be slowing down in
                  many parts of the world...
                </p>
              </div>
            </div>
          </div>
        </NavLink>
        <NavLink to="/blog6">
          <div className="col">
            <div className="card">
              <img src={art6_image} className="card-img-top" alt="..." />
              <div className="card-body">
                <h5 className="card-title">Serious games for seniors</h5>
                <p class="card-text">
                  <small className="text-muted">
                    Published on 25 April 2023
                  </small>
                </p>
                <p className="card-text">
                  One of the biggest challenges we face today is that, in the
                  European Union alone, ...
                </p>
              </div>
            </div>
          </div>
        </NavLink>
      </div>

      <div className="row row-cols-1 row-cols-sm-1 row-cols-md-3 blogs">
        <NavLink to="/blog7">
          <div className="col">
            <div className="card">
              <img src={art7_image} className="card-img-top" alt="..." />
              <div className="card-body">
                <h5 className="card-title">Healthcare and AI</h5>
                <p class="card-text">
                  <small className="text-muted">
                    Published on 25 July 2023
                  </small>
                </p>
                <p className="card-text">
                  The emergence of artificial intelligence (AI) in healthcare
                  has been ground-breaking...
                </p>
              </div>
            </div>
          </div>
        </NavLink>
        <NavLink to="/blog8">
          <div className="col">
            <div className="card">
              <img src={art8_image} className="card-img-top" alt="..." />
              <div className="card-body">
                <h5 className="card-title">
                  CAREUP in the landscape of the AgTech market worldwide
                </h5>
                <p class="card-text">
                  <small className="text-muted">
                    Published on 15 August 2023
                  </small>
                </p>
                <p className="card-text">
                  "AgTech" is a label that encompasses technological offerings
                  tailored to older adults...
                </p>
              </div>
            </div>
          </div>
        </NavLink>
        <NavLink to="/blog9">
          <div className="col">
            <div className="card">
              <img src={art9_image} className="card-img-top" alt="..." />
              <div className="card-body">
                <h5 className="card-title">
                  Challenges for Assistive Technologies That Target Older
                  Adults: Using Examples from AAL Funded Projects and the wider
                  literature
                </h5>
                <p class="card-text">
                  <small className="text-muted">
                    Published on 20 September 2023
                  </small>
                </p>
                <p className="card-text">
                  Dementia cases are projected to increase to 152 million by
                  2050 ..
                </p>
              </div>
            </div>
          </div>
        </NavLink>
        
      </div>

      <div className="row row-cols-1 row-cols-sm-1 row-cols-md-3 blogs">
        <NavLink to="/blog10">
          <div className="col">
            <div className="card">
              <img src={art10_image} className="card-img-top" alt="..." />
              <div className="card-body">
                <h5 className="card-title">
                  How to increase Intrinsic Capacity?
                </h5>
                <p class="card-text">
                  <small className="text-muted">
                    Published on 31 October 2023
                  </small>
                </p>
                <p className="card-text">
                  Intrinsic capacity refers to the physical and mental
                  attributes that individuals possess that enable them to
                  perform daily ...
                </p>
              </div>
            </div>
          </div>
        </NavLink>
        <NavLink to="/blog11">
          <div className="col">
            <div className="card">
              <img src={art11_image} className="card-img-top" alt="..." />
              <div className="card-body">
                <h5 className="card-title">
                  eHealth platforms for the independent living and active ageing
                </h5>
                <p class="card-text">
                  <small className="text-muted">
                    Published on 30 November 2023
                  </small>
                </p>
                <p className="card-text">
                In an era where populations are aging rapidly, and chronic diseases are on the rise, 
                the demand for innovative solutions ...
                </p>
              </div>
            </div>
          </div>
        </NavLink>
        <NavLink to="/blog13">
          <div className="col">
            <div className="card">
              <img src={art13_image} className="card-img-top" alt="..." />
              <div className="card-body">
                <h5 className="card-title">
                  Safeguarding Healthcare: Navigating the Security Landscape of AI
                </h5>
                <p class="card-text">
                  <small className="text-muted">
                    Published on 22 December 2023
                  </small>
                </p>
                <p className="card-text">
                  In the modern healthcare landscape, the integration of artificial intelligence (AI) has heralded a new era of efficiency, 
                  accuracy, and innovation ... 
                </p>
              </div>
            </div>
          </div>
        </NavLink>
      </div>

      <div className="row row-cols-1 row-cols-sm-1 row-cols-md-3 blogs">
        <NavLink to="/blog12">
          <div className="col">
            <div className="card">
              <img src={art12_image} className="card-img-top" alt="..." />
              <div className="card-body">
                <h5 className="card-title">
                  Overcoming Challenges in Healthcare Digitalization for the Elderly
                </h5>
                <p class="card-text">
                  <small className="text-muted">
                    Published on 31 January 2024
                  </small>
                </p>
                <p className="card-text">
                The digital revolution has transformed and enhanced many aspects of our lives ... 
                </p>
              </div>
            </div>
          </div>
        </NavLink>
        <NavLink to="/blog14">
          <div className="col">
            <div className="card">
              <img src={art14_image} className="card-img-top" alt="..." />
              <div className="card-body">
                <h5 className="card-title">
                  Home health monitoring technologies for older adults: user acceptance, issues and challenges
                </h5>
                <p class="card-text">
                  <small className="text-muted">
                    Published on 29 Febuary 2024
                  </small>
                </p>
                <p className="card-text">
                Smart home and health monitoring technologies are increasingly emerging as the future for managing 
                chronic diseases and allowing people to age ...
                </p>
              </div>
            </div>
          </div>
        </NavLink>
        <NavLink to="/blog15">
          <div className="col">
            <div className="card">
              <img src={art15_image} className="card-img-top" alt="..." />
              <div className="card-body">
                <h5 className="card-title">
                  The Impact of Cybersecurity on Health Systems: Challenges and Solutions
                </h5>
                <p class="card-text">
                  <small className="text-muted">
                    Published on 15 April 2024
                  </small>
                </p>
                <p className="card-text">
                  In an age where digitization is revolutionizing every aspect of our lives, the healthcare sector is not exempt from the transformative 
                  power of technology. Electronic health records (EHRs), telemedicine ...
                </p>
              </div>
            </div>
          </div>
        </NavLink>
      </div>

      <div className="row row-cols-1 row-cols-sm-1 row-cols-md-3 blogs">
        <NavLink to="/blog16">
          <div className="col">
            <div className="card">
              <img src={art16_image} className="card-img-top" alt="..." />
              <div className="card-body">
                <h5 className="card-title">
                  Informal carers and the privacy regulations: how to deal with data management and data access?
                </h5>
                <p class="card-text">
                  <small className="text-muted">
                    Published on 17 April 2024
                  </small>
                </p>
                <p className="card-text">
                  Informal carers, i.e. family members or friends, play a vital role in providing assistance and support to individuals 
                  with chronic illnesses, disabilities, or age-related conditions. ...
                </p>
              </div>
            </div>
          </div>
        </NavLink>
        <NavLink to="/blog17">
        <div className="col">
          <div className="card">
            <img src={art17_image} className="card-img-top" alt="..." />
            <div className="card-body">
              <h5 className="card-title">
                Data Exchange Advances and Standards in Computer and Information Systems Revolutionizing Healthcare
              </h5>
              <p class="card-text">
                <small className="text-muted">
                  Published on 18 April 2024
                </small>
              </p>
              <p className="card-text">
                Effective data exchange is paramount for improving patient care, enhancing clinical decision-making, and advancing medical research. ...
              </p>
            </div>
          </div>
        </div>
        </NavLink>
        <NavLink to="/blog18">
        <div className="col">
          <div className="card">
            <img src={art18_image} className="card-img-top" alt="..." />
            <div className="card-body">
              <h5 className="card-title">
                Dependent living and caregiver burden
              </h5>
              <p class="card-text">
                <small className="text-muted">
                  Published on 19 April 2024
                </small>
              </p>
              <p className="card-text">
                The average life expectancy at birth has, and will continue to, increase in the modern era. According to the United Nations, 
                the population over the age of 65 in Europe has more than tripled since ...
              </p>
            </div>
          </div>
        </div>
        </NavLink>                 
      </div>

      <div className="row row-cols-1 row-cols-sm-1 row-cols-md-3 blogs">
        <NavLink to="/blog19">
          <div className="col">
            <div className="card">
              <img src={art19_image} className="card-img-top" alt="..." />
              <div className="card-body">
                <h5 className="card-title">
                  Gait analysis – what, why and how?
                </h5>
                <p class="card-text">
                  <small className="text-muted">
                    Published on 13 May 2024
                  </small>
                </p>
                <p className="card-text">
                  As nowadays we live in the quickly ageing society, a need for the novel methods of 
                  the quick health and wellbeing assessment of the older adults is emerging. ...
                </p>
              </div>
            </div>
          </div>
          </NavLink>
          <NavLink to="/blog20">
          <div className="col">
            <div className="card">
              <img src={art20_image} className="card-img-top" alt="..." />
              <div className="card-body">
                <h5 className="card-title">
                  Creating Better Technology for Older Adults: How User-Focused Design Can Make a Difference
                </h5>
                <p class="card-text">
                  <small className="text-muted">
                    Published on 17 June 2024
                  </small>
                </p>
                <p className="card-text">
                  The global demographic shift towards an aging population presents a significant 
                  challenge and opportunity for technology developers to create solutions that cater 
                  to the unique needs of older adults. ...
                </p>
              </div>
            </div>
          </div>
          </NavLink>
          <NavLink to="/blog21">
          <div className="col">
            <div className="card">
              <img src={art21_image} className="card-img-top" alt="..." />
              <div className="card-body">
                <h5 className="card-title">
                  Intrinsic Capacity and Positive Health: Concepts and Implications. The CAREUP case
                </h5>
                <p class="card-text">
                  <small className="text-muted">
                    Published on 16 July 2024
                  </small>
                </p>
                <p className="card-text">
                  The concepts of Intrinsic Capacity and Positive Health are central to modern approaches in health and well-being, 
                  especially in the context of aging populations and preventive healthcare. ...
                </p>
              </div>
            </div>
          </div>
          </NavLink>
        </div>

        <div className="row row-cols-1 row-cols-sm-1 row-cols-md-3 blogs">
          <NavLink to="/blog22">
            <div className="col">
              <div className="card">
                <img src={art22_image} className="card-img-top" alt="..." />
                <div className="card-body">
                  <h5 className="card-title">
                    How to promote the adoption and sustainability of Health tecnologies for elderly people
                  </h5>
                  <p class="card-text">
                    <small className="text-muted">
                      Published on 19 August 2024
                    </small>
                  </p>
                  <p className="card-text">                    
                    Promoting the adoption and sustainability of health technologies for elderly people involves 
                    addressing unique challenges and leveraging opportunities to enhance their well-being. ...
                  </p>
                </div>
              </div>
            </div>
          </NavLink>
          <NavLink to="/blog23">
            <div className="col">
              <div className="card">
                <img src={art23_image} className="card-img-top" alt="..." />
                <div className="card-body">
                  <h5 className="card-title">
                    Frontiers 2024 in Monitoring Older Adults for Inclusive Health and Care
                  </h5>
                  <p class="card-text">
                    <small className="text-muted">
                      Published on 16 September 2024
                    </small>
                  </p>
                  <p className="card-text">                    
                    The world is facing a dramatic demographic transition, with populations aging at unprecedented rates. 
                    According to the World Health Organization (WHO), the global population of persons aged 60 and older is 
                    predicted to more than double by 2050, reaching 2.1 billion. ...
                  </p>
                </div>
              </div>
            </div>
          </NavLink>
          </div>

    </div>
    
  );
};

export default Blogs;
