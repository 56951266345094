import "./Profiles.css";
const Okey = () => {
  return (
    <div className="container" id="Profiles">
      <div className="row" id="main-title">
        <h1>Orthokey</h1>
      </div>
      <div className="row" id="partner-intro">
        <div className="col">
          <p id="partnerInroduction">
            OKEY is a SME with high-qualified expertise in the design,
            production and commercialization of innovative digital solutions for
            healthcare and clinical outcome assessment. Based on a co-creation
            approach with stakeholders’ involvement Orthokey drives innovation
            integrating clinicians’ experiences with smart and usable
            technologies and methods. Orthokey has a specific expertise on smart
            wearable technologies, Artificial Intelligence, Smart Interface for
            user interaction and Engagement, Smart Service for data Gathering
            and Behavioural Change, system architecture, workflow modelling,
            heterogeneous data sources integration, instruments design,
            Mechatronics, Computer Assisted Surgery as well as development and
            deployment of distributed platform for IT based healthcare services.
            <br />
            <p>
              OKEY has an important portfolio of solution and services that
              range from computer assisted surgical platforms for orthopedic
              applications, smart digital instruments for diagnosis and
              outpatient rehabilitation assessment, connected health solutions
              for chronic patients, integrated data storage and multi-parametric
              clinical reports generation, business process analysis and
              workflow redesign, workflow management platform, clinical and QoL
              outcomes assessment. The company places significant efforts in
              research and innovation activities with reference international
              clinical and research centres, and it is engaged in basic and
              application-oriented research for ICT solutions in healthcare
              area. To know more about us visit our{" "}
              <a href="https://www.orthokey.com" target="_blank" rel="noreferrer">
                website
              </a>
            </p>
          </p>
        </div>
      </div>
      <div className="row">
        <h2 id="projectRole">Project Role</h2>
      </div>

      <div className="row" id="projectRoleDetails">
        <div className="col">
          <div id="projectRoleIntroduction">
            OKEY will support CAREUP through:
            <ul>
              <li>
                Investigation of methodologies to sustain the CAREUP solution,
                exploitation and its value proposition, elaboration of impact
                assurance strategies
              </li>
              <li>
                OKEY will collaborate closely in all business model development
                activities.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row">
        <h2 id="projectRelatedExpertise">Related Expertise </h2>
      </div>

      <div className="row" id="projectRelatedExpertiseDetails">
        <div className="col">
          <p id="projectRelatedExpertiseIntroduction">
            OKEY has already been involved in several strategic regional and
            national projects in the ICT, e-health domains and it is therefore
            established in addressing technical, exploitation and legal issues
            associated with large, multi- disciplinary projects and provides
            support for Impact assessment analysis and KPIs definition with
            reference to the Exploitation strategy definition in the field of
            socio healthcare; improve the well-being, health, quality of life
            and active ageing of populations.
          </p>
        </div>
      </div>

      <div className="row">
        <h2 id="teamMembers">Team Members</h2>
      </div>
      <div className="row">
        <div className="col" id="teamMemberDetails">
          <h5 id="teamMemberName">Pietro Dionisio (M)</h5>
          <p id="memberIntroduction">
            Master’s degree in political science from the University of Florence
            in 2015. He has experience in innovation management, global health
            policies and Universal Health Coverage strategies supported by the
            innovation introduction. Author of several articles in the fields of
            health, social and health economic policies for numerous Journals,
            including the British Medical Journal (The shortage of medicines in
            Venezuela is a humanitarian crisis, BMJ, 2016). He has more than 5
            years of experience in innovations actions cofounded under European
            Regional Development Fund, European Social Fund, AAL Programme, and
            H2020. Project Manager for OKEY of the Large-scale pilot such as
            PHARAON (G.A. 857188).
          </p>
        </div>
      </div>
    </div>
  );
};

export default Okey;
