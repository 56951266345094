import "./Profiles.css";
const Sph = () => {
  return (
    <div className="container">
      <div className="row" id="main-title">
        <h1>SAPHYRION</h1>
      </div>
      <div className="row" id="partner-intro">
        <div className="col">
        <div id="partnerInroduction">
              SPH, Saphyrion Sagl, is a privately owned R&D company focused on designing 
              sophisticated sensors, application-specific integrated circuits and embedded 
              systems for demanding applications in space engineering, industrial automation 
              and healthcare. Beside an extensive expertise in electronics engineering, 
              it has an in-house capability in the design of integrated circuits and their 
              production; SPH devices have been widely exploited in many electronic products, 
              manufactured in volumes of several million units by major consumer electronics 
              producers. SPH is able to manage the whole development process, spanning from 
              the first proposition of the concept to the release of preseries production. 
              SPH can leverage on a high-level scientific team comprising 
              professionals with >20 years of continuous involvement in microelectronics and 
              digital signal processing.SPH was already in several AAL CMU projects such as: 
              NITICS and ACESO. To know more about us visit our{" "}
              <a href="https://saphyrion.ch" target="_blank" rel="noreferrer">                
                website
              </a>
              .
          </div>
        </div>
      </div>
      <div className="row">
        <h2 id="projectRole">Project Role</h2>
      </div>

      <div className="row" id="projectRoleDetails">
        <div className="col">
          <p id="projectRoleIntroduction">
            In CAREUP, SPH is involved in platform and services design and in the 
            devolopment of business modelling and planning.
          </p>
        </div>
      </div>
      <div className="row">
        <h2 id="projectRelatedExpertise">Related Expertise </h2>
      </div>

      <div className="row" id="projectRelatedExpertiseDetails">
        <div className="col">
          <p id="projectRelatedExpertiseIntroduction">
          SPH has wide expertise in electronics engineering, business, certification of complex systems. 
          In 2017 SPH developed a novel medical instrument - VascuLight (VL) - for fast 
          and contactless screening of the vascular system. SPH has a consolidate experience 
          in the certification of complex systems in compliance with EU and US standards for 
          healthcare electronics.
          </p>
        </div>
      </div>
      
      <div className="row">
        <h2 id="teamMembers">Team Members</h2>
      </div>
      <div className="row">
        <div className="col" id="teamMemberDetails">
          <h5 id="teamMemberName">Dr. Flavio Fontana</h5>
          <p id="memberIntroduction">
            He is in charge of business development. He has been contract professor at the 
            University of Padua (Italy) for 3 years. He is inventor in 13 international or
            Italian patents.
          </p>
        </div>
      </div>

      
    </div>
  );
};

export default Sph;
