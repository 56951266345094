import "./Consortium.css";
import { NavLink } from "react-router-dom";
import consortiumImage from "../../static/img/consortium.png";
const Consortium = () => {
  return (
    <div className="container">
      <div className="row" id="main-title">
        <h1>Consortium</h1>
      </div>
      <div className="row">
        <p id="consortium-intro">
          The <span id="careup">CAREUP</span> consortium consists of nine
          <span id="careup"> partners</span> from five different{" "}
          <span id="careup">countries</span>
        </p>
      </div>
      <div className="row">
        <img src={consortiumImage} id="consortium-image" />
      </div>
      <div className="row">
        <h2 className="" id="profile-header">
          Consortium Profiles
        </h2>
      </div>
      <div className="row">
        <table className="table table-bordered" id="consortium-table">
          <thead>
            <tr>              
              <th width="50%"><b>Parntner name </b></th>
              <th width="25%"><b>Country</b></th>
              <th width="25%"><b>Category</b></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <NavLink to="/exys">ECLEXYS Sagl (EXYS)</NavLink>
              </td>
              <td>Switzerland</td>
              <td>SME</td>
            </tr>
            <tr>
              <td>
                <NavLink to="/eurag">EURAG Österreich (EURAG)</NavLink>
              </td>
              <td>Austria</td>
              <td>END-USER</td>
            </tr>
            <tr>
              <td>
                <NavLink to="/inrca">National Institute of Health and Science on Aging - IRCCS (INRCA)</NavLink>
              </td>
              <td>Italy</td>
              <td>END-USER & R&D</td>
            </tr>
            <tr>
              <td>
                <NavLink to="/sph">Saphyrion Sagl (SPH)</NavLink>
              </td>
              <td>Switzerland</td>
              <td>SME</td>
            </tr>
            <tr>
              <td>
                <NavLink to="/citst">Centrul IT pentru Stiinta si Tehnologie (CITST)</NavLink>
              </td>
              <td>Romania</td>
              <td>SME</td>
            </tr>
            <tr>
              <td>
                <NavLink to="/wut">Warsaw University of Technology (WUT)</NavLink>
              </td>
              <td>Poland</td>
              <td>RESEARCH-UNIVERSITY</td>
            </tr>
            <tr>
              <td>
                <NavLink to="/aaif">Ana Aslan International Foundation (AAIF)</NavLink>
              </td>
              <td>Romania</td>
              <td>END-USER</td>
            </tr>
            <tr>
              <td>
                <NavLink to="/oct">Octilium Sagl (OCT)</NavLink>
              </td>
              <td>Switzerland</td>
              <td>SME</td>
            </tr>
            <tr>
              <td>
                <NavLink to="/okey">Orthokey Italia S.r.l. (OKEY)</NavLink>
              </td>
              <td>Italy</td>
              <td>SME</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Consortium;
