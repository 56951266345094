import "./Publications.css";
import { NavLink, Link } from "react-router-dom";

const Publications = () => {
  return (
    <div className="container">
      <div className="row" id="main-title">
        <h1>Publications, presentations, videos</h1>
      </div>
      <div className="row">
        <p id="publications-main-intro">
          CAREUP publications and presentations in scientific journals and public conferences. Video.
        </p>
      </div>      
      <div className="row" id="publications-title">
        <h3>
          Videos
        </h3>
      </div>
      <br />
      <i class="fa fa-film" id ="video-icon" aria-hidden="true"></i> &nbsp;&nbsp;
      <a href="../static/videos/CAREUP.mp4" target="_blanck" rel="noreferrer">
          CAREUP Video 
      </a>  
      <br /> <br /> <br /> 
      <div className="row" id="publications-title">
        <h3>
          Publications in journals and presentations in conferences
        </h3>
      </div>
      <br />
      <table className="table table-bordered" id="publications-table">
          <thead>
            <tr>              
              <th width="15%"><b>Project participant responsibles</b></th>
              <th width="65%"><b>Publication</b></th>
              <th width="10%"><b>Journal article</b></th>
              <th width="10%"><b>Presentation in conference</b></th>
            </tr>
          </thead>
          <tbody>
          <tr>
            <td>
              CITST, AAIF
            </td>
            <td>
              Conference presentation „An intergenerational perspective on gamification in education and research”. 
              Intl. Conference EDULEARN 2023, publisher IATED, 35 July 2023.                
            </td>
            <td></td>
            <td><a href="../static/conferences/CITST, AAIF_Conference presentation EDULEARN 2023.pdf" target="_blanck" rel="noreferrer">
            <i class="fa fa-file-powerpoint fa-2x" aria-hidden="true"></i></a>
            </td>
          </tr>
          <tr>
            <td>
              AAIF, INRCA, EURAG, OKEY, CITST, WUT, EXYS 
            </td>
            <td>
              Journal article „How technology-based interventions can sustain ageing well in the new decade through the user-driven approach”, 
              29 June, 2023.
            </td>
            <td><a href="../static/publications/sustainability-15-10330.pdf" target="_blanck" rel="noreferrer">
            <i class="fa fa-file-pdf fa-2x" id ="pdf-icon" aria-hidden="true"></i></a></td>
            <td></td>
          </tr>
          <tr>
            <td>
              AAIF
            </td>
            <td> 
              E-poster „CAREUP: integrated digital platform for individualized care", 
              presented at StressCongress 2023 organized by FAAI, 6th edition: "Challenges of Digitization in Medical Stress Management", Palatul 
              Parlamentului, Bucharest, Romania, 28-30 September 2023.
            </td>
            <td></td>
            <td><a href="../static/conferences/StressCongress2023 CAREUP _FAAI.pdf" target="_blanck" rel="noreferrer">
              <i class="fa fa-file-powerpoint fa-2x" aria-hidden="true"></i></a></td>            
          </tr>
          <tr>
            <td>
              AAIF
            </td>
            <td> 
              Conference presentation „The role of digital technologies in preventing the evolution of dementia",
              13th National Alzheimer's Conference, Bucharest, Romania, 1-4 March 2023.
            </td>
            <td></td>
            <td><a href="../static/conferences/e-poster AAIF_M Velciu si C. Paul.pptx.pdf" target="_blanck" rel="noreferrer">
              <i class="fa fa-file-powerpoint fa-2x" aria-hidden="true"></i></a></td>            
          </tr>
          <tr>
            <td>
              AAIF
            </td>
            <td> 
              Presentation for understanding the CAREUP platform (in romanian).
            </td>
            <td></td>
            <td><a href="../static/conferences/AAIF_presentation for understanding the platf CAREUP _romanian.pdf" target="_blanck" rel="noreferrer">
              <i class="fa fa-file-powerpoint fa-2x" aria-hidden="true"></i></a></td>            
          </tr>
          <tr>
            <td>
              CITST
            </td>
              <td> 
                Irina Mocanu, Oana Cramariuc, Dorin Ionut Stanciu. An Intelligent Application for Improving Health 
                Conditions through Gamification. 24th International Conference on Control Systems and Computer Science – CSCS24, 24-26 May 2023.              
            </td>
            <td><a href="../static/publications/CSCS24_2023.pdf" target="_blanck" rel="noreferrer">
              <i class="fa fa-file-pdf fa-2x" id ="pdf-icon" aria-hidden="true"></i></a></td>
            <td><a href="../static/conferences/CSCS_2023_Irina_Mocanu.pdf" target="_blanck" rel="noreferrer">
              <i class="fa fa-file-powerpoint fa-2x" aria-hidden="true"></i></a></td>            
          </tr>
          <tr>
            <td>
              CITST
            </td>
            <td>
              Irina Mocanu, Alex Imad Awada, Oana Cramariuc. A System for Engaging Older Adults in Maintaining an Active and Healthy Life Through Games.
              19th eLearning and Software for Education - eLSE Conference, 27-28 April 2023.              
            </td>
            <td><a href="../static/publications/Else_2023.pdf" target="_blanck" rel="noreferrer">
              <i class="fa fa-file-pdf fa-2x" id ="pdf-icon" aria-hidden="true"></i></a></td>
            <td><a href="../static/conferences/eLSE2023_Irina_Mocanu.pdf" target="_blanck" rel="noreferrer">
              <i class="fa fa-file-powerpoint fa-2x" aria-hidden="true"></i></a></td>   
          </tr>
          <tr>
            <td>
              CITST
            </td>
            <td>
              Roxana Elena Stiuca, Irina Mocanu. Character Animation using LSTM Networks.
              46th International Conference on Telecomunications and Signal Processing, TSP 2023, 12-14 iulie 2023              
            </td>
            <td><a href="../static/publications/TSP_2023.pdf" target="_blanck" rel="noreferrer">
              <i class="fa fa-file-pdf fa-2x" id ="pdf-icon" aria-hidden="true"></i></a></td>
            <td><a href="../static/conferences/tsp-2023-ID-78.pdf" target="_blanck" rel="noreferrer">
              <i class="fa fa-file-powerpoint fa-2x" aria-hidden="true"></i></a></td>   
          </tr>          
          <tr>
            <td>
              WUT
            </td>
            <td>
              J. Jędrys and J. Kołakowski, System for older adults activity  investigation based on environmental parameters, Przegląd Telekomunikacyjny - Wiadomości Telekomunikacyjne, vol. nr 4, pp. 357–360, 2023, doi: 10.15199/59.2023.4.81.
            </td>
            <td><a href="../static/publications/WUT_J. Jędrys and J. Kołakowski,  System for older adults activity  investigation based on environmental parameters.pdf" target="_blanck" rel="noreferrer">
              <i class="fa fa-file-pdf fa-2x" id ="pdf-icon" aria-hidden="true"></i></a></td>
            <td><a href="../static/publications/WUT_J. Jędrys and J. Kołakowski,  System for older adults activity  investigation based on environmental parameters.pdf" target="_blanck" rel="noreferrer">
              <i class="fa fa-file-powerpoint fa-2x" aria-hidden="true"></i></a></td>
          </tr>
          <tr>
            <td>
              WUT
            </td>
            <td>
              K. Mandecki and M. Kołakowski, Using information on user’s activity to improve accuracy in an ultra-wideband positioning system, Przegląd Telekomunikacyjny - Wiadomości Telekomunikacyjne, vol. nr 4, pp. 212–215, 2023, doi: 10.15199/59.2023.4.45.
            </td>
            <td><a href="../static/publications/WUT_K. Mandecki and M. Kołakowski, Using information on user’s activity to improve accuracy in an ultra-wideband positioning system.pdf" target="_blanck" rel="noreferrer">
              <i class="fa fa-file-pdf fa-2x" id ="pdf-icon" aria-hidden="true"></i></a></td>
            <td><a href="../static/publications/WUT_K. Mandecki and M. Kołakowski, Using information on user’s activity to improve accuracy in an ultra-wideband positioning system.pdf" target="_blanck" rel="noreferrer">
              <i class="fa fa-file-powerpoint fa-2x" aria-hidden="true"></i></a></td>
          </tr>
          <tr>
            <td>
              WUT
            </td>
            <td>
              W. Hrycenko, M. Twarowski, and V. Djaja-Jośko, Infrastructure of the elderly activity monitoring, Przegląd Telekomunikacyjny. - Wiadomości Telekomunikacyjne, vol. nr 4, pp. 353–356, 2023.doi: 10.15199/59.2023.4.80.
            </td>
            <td><a href="../static/publications/WUT_W. Hrycenko, M. Twarowski, and V. Djaja-Josko, Infrastructure of the elderly activity monitoring system.pdf" target="_blanck" rel="noreferrer">
              <i class="fa fa-file-pdf fa-2x" id ="pdf-icon" aria-hidden="true"></i></a></td>
            <td><a href="../static/publications/WUT_W. Hrycenko, M. Twarowski, and V. Djaja-Josko, Infrastructure of the elderly activity monitoring system.pdf" target="_blanck" rel="noreferrer">
              <i class="fa fa-file-powerpoint fa-2x" aria-hidden="true"></i></a></td>
          </tr>
          <tr>
            <td>
              EURAG
            </td>
            <td>
              CAREUP Article "Haben Sie schon einmal von der „Intrinsischen Kapazität“ gehört?" in EURAG Newsletter (p. 3), Spring 2023.
            </td>
            <td><a href="../static/publications/EURAG news Frühling 2023.pdf" target="_blanck" rel="noreferrer">
              <i class="fa fa-file-pdf fa-2x" id ="pdf-icon" aria-hidden="true"></i></a></td>
            <td></td>
          </tr>
          <tr>
            <td>
              EURAG
            </td>
            <td>
              CAREUP General presentation about the project to different target groups, 2023.
            </td>
            <td></td>
            <td><a href="../static/conferences/EURAG_Careup presentation_general.pdf" target="_blanck" rel="noreferrer">
              <i class="fa fa-file-powerpoint fa-2x" aria-hidden="true"></i></a></td>            
          </tr>
          <tr>
            <td>
              OKEY
            </td>
            <td> 
              Integrated Care Platform Based on the Monitoring of Older Individual Intrinsic Capacity for Inclusive Health (CAREUP).
              Presentation of the CAREUP project within the MEDEA ACADEMY, 26-29 June 2023.                
            </td>
            <td></td>
            <td><a href="../static/publications/CareUp_MEDAC_June2023.pdf" target="_blanck" rel="noreferrer">
              <i class="fa fa-file-powerpoint fa-2x" aria-hidden="true"></i></a>
            </td>
          </tr>            
          </tbody>
        </table>          
        <br />       
      <br /><br />
    </div>  
  );
};

export default Publications;
