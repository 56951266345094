import { NavLink } from "react-router-dom";
import "./Blogs.css";
import art21_image from "../../../static/img/art21.jpg";

const Blog21 = () => {
  return (    
      <div className="container ">
        <div className="row blogs" id="main-title">
          <NavLink to="/blogs">
            <h1>Blogs</h1>
          </NavLink>
        </div>
        <div className="row full_blogs">
          <h4>
            Intrinsic Capacity and Positive Health: Concepts and Implications. The CAREUP case
          </h4>
          <p class="card-text">
            <small className="text-muted">Published on 16 July 2024</small>
          </p>
          <img src={art21_image} className="agtech" alt="..." />
            <p align="center"><i>(source: A Guide to healthy Aging &nbsp;
              <a href='https://www.news-medical.net/health/A-Guide-to-Healthy-Aging.aspx' target="_blank" rel="noreferrer">
                https://www.news-medical.net/health/A-Guide-to-Healthy-Aging.aspx)</a>)</i>
              </p>                
          <p>
            The concepts of Intrinsic Capacity and Positive Health are central to modern approaches in health and well-being, 
            especially in the context of aging populations and preventive healthcare. Although both concepts aim to enhance 
            individual health outcomes, they differ significantly in their definitions, approaches, and applications. 
            This blog post compares intrinsic capacity and positive health, highlighting their definitions, measurement, 
            determinants, and applications in healthcare, in particular for the CAREUP system.
          </p>          
          <p>
            Intrinsic capacity (IC) is a holistic concept introduced by the World Health Organization (WHO) as part of its 
            Healthy Aging framework. It encompasses all the physical and mental capacities that an individual can draw upon at 
            any point in time. Intrinsic capacity is dynamic, reflecting changes in an individual's health status over time and 
            influenced by various factors such as diseases, injuries, and aging processes.
          </p>
          <p>
            Positive Health (PH) is an approach that emphasizes enhancing individuals' resilience, adaptability, and overall well-being 
            rather than merely focusing on the absence of disease. It integrates physical, mental, and social health dimensions.
          </p>
          <p></p>
          <p>
            <b>Applications in Healthcare</b> <br /> 
              Incorporating intrinsic capacity into healthcare practices involves a shift from disease-centric approaches to 
              person-cantered care. This approach emphasizes the importance of maintaining and improving overall capacity rather than 
              merely treating diseases. It supports the development of integrated care models that address multiple dimensions of health 
              and well-being.
          </p>
          <p>
            Traditional healthcare systems often focus on diagnosing and treating specific physical health conditions. 
            This approach relies heavily on medical interventions, pharmacological treatments, and surgical procedures. While effective in 
            managing acute and chronic diseases, it may overlook broader aspects of an individual's well-being that are essential for healthy aging.
          </p>
          <p>
            The CAREUP project, by leveraging the IC and PH concepts and AI methods to compute their trends, intends precisely to fill this 
            gap in the promotion of well-being, and for the preservation of physical and mental health of the older adults.
          </p>
          <p></p>
          <p>
            <b>Determinants</b> <br /> 
            Intrinsic capacity is influenced by a wide range of factors, including genetic predisposition, lifestyle choices, 
            environmental conditions, and social determinants of health. It is also affected by the presence of chronic diseases, 
            mental health conditions, and access to healthcare services. The interaction between these factors determines the overall 
            level of intrinsic capacity an individual can maintain.
          </p>     
          <p>
            Applications related to IC include assessment tools (instruments like the WHO's Integrated Care for Older People (ICOPE) 
            framework assess and monitor intrinsic capacity in elderly populations), healthcare interventions 
            (personalized interventions, such as physical exercises, cognitive training, nutritional support, and social engagement 
            activities, are designed to maintain or improve these capacities), policy making (governments and health organizations use 
            intrinsic capacity assessments to inform policies and programs aimed at aging populations).
          </p>
          <p>
            Positive Health was conceptualized by researchers such as Martin Seligman and developed within the realm of positive psychology. 
            It aims to foster a holistic view of health that includes psychological strengths and well-being, namely emotional well-being 
            (positive emotions, life satisfaction, and happiness), social well-being (quality of social relationships and support networks), 
            psychological well-being (self-acceptance, purpose in life, and personal growth), physical well-being (physical fitness, 
            absence of chronic diseases, and health-promoting behaviours).
          </p>
          <p>
            Typical applications of PH include well-being programs by organizations implementing Positive Health programs that promote mental 
            health, stress management, and lifestyle modifications, preventive healthcare, emphasizing proactive health measures such as 
            regular physical activity, balanced nutrition, and mental health support, public health initiatives, like campaigns and community 
            programs aimed at enhancing overall life satisfaction and well-being.
          </p>
          <p></p>
          <p>
            <b>Measurement and representation in CAREUP</b> <br /> 
            The assessment of intrinsic capacity involves evaluating multiple domains, including locomotion, vitality, sensory functions, 
            cognition, and psychological well-being. Tools for measuring intrinsic capacity are still being developed and validated, with some 
            existing assessments integrating self-reported questionnaires, clinical evaluations, and performance-based tests. 
          </p>
          <p>
            IC is computed in CAREUP by means of questionnaires encompassing the five aforementioned domains: for instance, PHQ-9 
            (Patient Health Questionnaire-9) for the psychological domain, or MoCA (Montreal Cognitive Assessment) for cognition. 
            Then IC is updated through responses provided by the patient (primary end-user) using a tablet app. It is then displayed separately 
            (component-wise) on a daily basis assuming steadiness and continuity. The global IC is then simply the arithmetic mean across all
            IC components.
          </p>
          <p>
            It is worth to say that the obtained value only informs on the general trend of the deterioration, therefore, to get the 
            real value from the Intrinsic Capacity, the IC trends of the five domains are represented separately in a chart (a radar 
            diagram or another graphical representation) in the CAREUP platform, enabling to see how far they are from the domains’ 
            central goals, hence providing an effective tool to understand their evolution.
          </p>
          <p>
            On the other hand, positive health is typically assessed through a combination of subjective self-reports and objective 
            assessments. Common data collecting and evaluation tools include self-report surveys and questionnaires, health interviews, 
            clinical assessments, assessment scales, activity parameters. These measurements provide specific data points that 
            can be tracked over time to monitor changes.
          </p> 
          <p>
            PH is not computed in CAREUP, although data from PH questionnaires are also gathered by means of the same tablet’s app and 
            compiled by the patient. In CAREUP, the PH’s physical well-being related parameters are measured separately, through 
            questionnaires and by direct measurement from body sensors, and they are used to promptly provide information to the patient 
            about the his/her health status and well-being, and to send early warnings and alerts to the informal caregivers 
            (secondary users) in case of necessity.
          </p>
          <p></p>
          <p>
            <b>Implications for Policy</b> <br /> 
              Policies promoting intrinsic capacity require a comprehensive approach that addresses the diverse factors affecting an 
              individual's capacities. This includes enhancing access to healthcare, promoting healthy lifestyles, improving social 
              and environmental conditions, and fostering supportive communities. Such policies aim to enable individuals to maintain 
              their intrinsic capacity throughout their lifespan.
          </p>
          <p>
              Policymakers can use aggregated data from Positive Health assessments to shape policies that promote well-being at a 
              societal level, such as creating supportive environments for mental health, encouraging social cohesion, and ensuring 
              access to healthcare.
          </p>
          <p>
            Health policies traditionally focus on preventing and managing diseases through public health initiatives, healthcare access, 
            $and health education. These policies aim to reduce the prevalence of risk factors such as smoking, poor diet, and physical 
            inactivity. While effective, there is a growing recognition of the need to integrate broader determinants of health into 
            policy frameworks.
          </p>
          <p></p>   
          <p>
            In conclusion, both Intrinsic Capacity and Positive Health offer valuable frameworks for improving individual health outcomes. 
            While Intrinsic Capacity provides a structured approach to maintaining functional abilities in older adults, Positive Health 
            promotes a holistic view of well-being applicable to all ages. Integrating these concepts can lead to more comprehensive and 
            effective health strategies, ultimately enhancing quality of life and promoting sustainable health across populations. 
            This is a principal goal for the CAREUP project. The CAREUP project aims to address these topics in caregiving and the promotion 
            of well-being by utilizing the concepts of IC and PH, specifically targeting the physical and mental health preservation of older 
            adults.
          </p>

          <p></p>
          <div id="article-references">
            <h5><i>Bibliography</i></h5>            
              <li>
                World Health Organization. (2020). WHO Integrated Care for Older People (ICOPE): Guidelines on community-level interventions to manage declines in intrinsic capacity. 
                Retrieved from <a href="https://www.who.int/publications/i/item/9789241550109" target="_blank" rel="noreferrer">WHO website</a>.
              </li>
              <li>
                Seligman, M. E. P. (2011). Flourish: A visionary new understanding of happiness and well-being. New York: Free Press.
              </li>
              <li>
                Vernooij-Dassen, M., & Jeon, Y. H. (2016). Social health and dementia: The power of human capabilities. International Psychogeriatrics, 28(5), 701-703.
              </li>
              <li>
                Kagan, S. H., & Melillo, K. D. (2020). Geriatric Nursing Protocols for Best Practice. Springer Publishing Company.
              </li>
              <li>
                Huber, M., van Vliet, M., Giezenberg, M., Winkens, B., Heerkens, Y., Dagnelie, P. C., & Knottnerus, J. A. (2016). Towards a ‘patient-centred’ operationalisation of the new dynamic concept of health: a mixed methods study. BMJ Open, 6(1), e010091.
              </li>              
              <li> 
                PHQ-9 official site: &nbsp;              
                <a href="https://www.phqscreeners.com/" target="_blank" rel="noreferrer">
                  https://www.phqscreeners.com/
                </a>                
              </li>              
              <li>
                Montreal Cognitive Assessment, MoCA: &nbsp;
                <a href="https://geriatrictoolkit.missouri.edu/cog/MoCA-8.3-English-Test-2018-04.pdf" target="_blank" rel="noreferrer">
                  https://geriatrictoolkit.missouri.edu/cog/MoCA-8.3-English-Test-2018-04.pdf
                </a>                
              </li>              
            <p></p>
          </div>                 
        </div>           
        
        <script>window.scrollTo(0,0);</script>
      </div>
            
  );
};

export default Blog21;
