import "./Footer.css";
import axios from "axios";
// import aal from "../../static/img/ag.png";
import aal from "../../static/img/agnw.png";

const Footer = () => {
  return (
    <footer className="container-fluid">
      <div className="container">
        <div className="row content">
          <div className="col">
            <h2>Contact</h2>
            <p>
              ECLEXYS SAGL <br />
              Via dell'Inglese 6 <br />
              CH-6826 Riva San Vitale <br />
              Switzerland <br />
            </p>
            <p>Email:office[at]eclexys.com</p>
          </div>
          <div className="col">
            <img src={aal} id="agencies" />
            <p id="aal-p">
              This project has received funding from the AAL JP national
              agencies
            </p>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div
              className="page-footer font-small stylish-color-dark pt-4"
              id="foot"
            >
              <div className="footer-copyright text-center py-3">
                <label htmlFor="" id="copy">
                  ©{new Date().getFullYear()} CAREUP Consortium - All Rights
                  Reserved.
                </label>
                <a href="#"> </a>
              </div>
            </div>
          </div>
          <div className="row"></div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
