import "./Blogs.css";
import { NavLink } from "react-router-dom";
const Blog4 = () => {
  return (
    <div className="container  ">
      <div className="row blogs" id="main-title">
        <NavLink to="/blogs">
          <h1>Blogs</h1>
        </NavLink>
      </div>
      <div className="row full_blogs">
        <h4>Benefits of Digital Games for Seniors</h4>
        <p class="card-text">
          <small className="text-muted">Published on 17 January 2023</small>
        </p>
        <p>
          It is an acknowledged fact that there are several barriers when it
          comes to seniors and their access and employment of modern technology.
          According to Eurostat, in 2017, almost half (48%) of the EU population
          aged 65-74 years did not use the internet or a computer during the
          three months preceding the Community survey on ICT usage [1]. In
          contrast, the 2020 Eurostat reported improved internet usage, with
          only two fifths (39%) of the EU population aged 65-74 years not using
          the internet during the three months preceding the survey [2]. The
          improvement is visible, but, despite the encouraging statistics, there
          is still a substantial percentage of the senior population that has
          very limited or no contact with smart devices, such as smartphones,
          tablets, laptops, wearables, smart TVs etc.
        </p>
        <p>
          But why would the employment of technology and the internet be
          important for the senior population? Let’s take the example of digital
          games, as they are integrating parts of the CAREUP Project (see Figure
          1). As presented in several studies, games improve the mood and social
          participation of players by providing them with a place where they can
          interact with similar people [3,4]. For retired people, finding
          engaging activities can be hard sometimes. Games provide a welcoming
          distraction from the constant routine, entertaining seniors of all
          ages and abilities.
        </p>
        <p>
          Digital games have even greater advantages, as they can be played from
          the comfort of home, via a smart device of choice, and they make
          interaction with other people possible, be they family, friends, or
          other seniors. For instance, Sauvé et. al adapted a very popular game
          for seniors (Bingo) to the digital interface, designing it as simple
          as possible in order to make it accessible [5]. The game is called
          ‘Live Well, Live Healthy’ and its innovative character stems from the
          possibility of senior players of playing together, online, from
          different parts of their town, country, or even the globe.{" "}
        </p>
        <p>
          Games can also provide cognitive and physical benefits [6]. Nowadays,
          researchers in the aging field pair up with game developers to design
          games which are entertaining and accessible for seniors. The CAREUP
          consortium is just such a team, aiming to integrate cognitive
          stimulating games into their senior health monitoring platform. The
          scope of cognitive games is to help the senior population to at least
          maintain, if not improve, their cognitive functions, such as memory,
          attention, thinking, reasoning, task switching, decision making.
        </p>
        <p>
          An example of such a game is The Gifts Purchase game, designed by Chi
          et. al [7]. The game is played on a computer and the way it works is
          simple: the player is given a sum of money and instructions about the
          preferences of some fictional family members. Then, the player has to
          select the shops where they can buy gifts and choose what they think
          is more suitable for each member, taking into account not to exceed
          the allocated sum. This game helps to improve the planning ability and
          the organizational ability, in order to achieve the planned goals.
        </p>
        <p>
          There are also simpler games, for seniors with MCI. For instance, The
          MCI Rehab Suite contains 10 serious games for seniors with dementia
          which are designed to maintain and improve basic cognitive functions
          [8]. Besides this, the game suite can provide an informal measurement
          of the user's cognitive performance and an assessment of cognitive
          improvement by monitoring the total time played by the senior.
          Speaking of measurements of cognitive performance, researchers from
          the health and aging fields have started to convert assessment tests
          for MCI and other cognitive issues into entertaining ‘games’ in order
          to easily convince seniors to take part in them [9].
        </p>

        <p>
          The conclusion of this short analysis is that the senior population is
          growing steadily all around the globe, therefore the employment of
          technology, especially digital games aimed at its cognitive assessment
          and improvement, are becoming invaluable tools for formal and informal
          caregivers. Caregivers of all kinds are struggling to keep up with the
          aging phenomenon in their beneficiaries and serious games might be the
          solution to help reduce the strain on their personal lives and jobs.
        </p>
        <h5>Bibliography</h5>
        <ol>
          <li>
            Eurostat, 2019. Ageing Europe — looking at the lives of older people
            in the EU. [online] Available at:{" "}
            <a
              target="_blank"  rel="noreferrer"
              href="https://ec.europa.eu/eurostat/documents/3217494/10166544/KS-02-19%E2%80%91681-EN-N.pdf/c701972f-6b4e-b432-57d2-91898ca94893"
            >
              https://ec.europa.eu/eurostat/documents/3217494/10166544/KS-02-19%E2%80%91681-EN-N.pdf/c701972f-6b4e-b432-57d2-91898ca94893
            </a>
          </li>
          <li>
            Eurostat, 2021. Individuals – internet use (Database
            ISOC_CI_IFP_IU). [online] Available at:{" "}
            <a
              target="_blank"  rel="noreferrer"
              href="https://ec.europa.eu/eurostat/databrowser/view/ISOC_CI_IFP_IU__custom_915519/bookmark/table?lang=en&bookmarkId=1a84d7ea-0d93-40ff-a7c0-b3b3cfcd62e3"
            >
              https://ec.europa.eu/eurostat/databrowser/view/ISOC_CI_IFP_IU__custom_915519/bookmark/table?lang=en&bookmarkId=1a84d7ea-0d93-40ff-a7c0-b3b3cfcd62e3
              .Accessed at 10th of January 2023
            </a>
          </li>
          <li>
            Sauvé, L. et al., 2017. Can digital games help seniors improve their
            quality of life?. Communications in Computer and Information
            Science, pp. 179–192.{" "}
            <a
              href="https://doi.org/10.1007/978-3-319-63184-4_10"
              target="_blank"  rel="noreferrer"
            >
              https://doi.org/10.1007/978-3-319-63184-4_10
            </a>
          </li>
          <li>
            McLaughlin, A., Gandy, M., Allaire, J., & Whitlock, L., 2012.
            Putting Fun into Video Games for Older Adults. Ergonomics in Design,
            20(2), 13–22{" "}
            <a href="https://doi.org/10.1177/1064804611435654" target="_blank"  rel="noreferrer">
              https://doi.org/10.1177/1064804611435654
            </a>
          </li>
          <li>
            Sauvé, L., Renaud, L., Kaufman, D., & Duplàa, E., 2015. Validation
            of the Educational Game for Seniors: “Live Well, Live Healthy!”.
            Procedia - Social and Behavioral Sciences, 176, 674–682.
            doi:10.1016/j.sbspro.2015.01.526
          </li>
          <li>
            Basak, C., Boot, W. R., Voss, M. W., & Kramer, A. F., 2008. Can
            training in a real-time strategy video game attenuate cognitive
            decline in older adults? Psychology and Aging, 23(4), 765–777.{" "}
            <a href="https://doi.org/10.1037/a0013494" target="_blank"  rel="noreferrer">
              https://doi.org/10.1037/a0013494
            </a>
          </li>
          <li>
            Chi, H., Agama, E., & Prodanoff, Z. G., 2017. Developing serious
            games to promote cognitive abilities for the elderly. 2017 IEEE 5th
            International Conference on Serious Games and Applications for
            Health (SeGAH). doi:10.1109/segah.2017.7939279
          </li>
          <li>
            Skikos, G. and Goumopoulos, C., 2021. A tablet-based game tool for
            cognition training of seniors with mild cognitive impairment.
            Database Systems for Advanced Applications. DASFAA 2021
            International Workshops, pp. 355–364.
            doi:10.1007/978-3-030-73216-5_24
          </li>
          <li>
            Mezrar, S. and Bendella, F., 2022. A systematic review of serious
            games relating to cognitive impairment and dementia. Journal of
            Digital Information Management, 20(1), pp. 1.{" "}
            <a
              href="https://doi.org/10.6025/jdim/2022/20/1/01-09"
              target="_blank" rel="noreferrer"
            >
              https://doi.org/10.6025/jdim/2022/20/1/01-09.
            </a>
          </li>
        </ol>
      </div>
    </div>
  );
};

export default Blog4;
