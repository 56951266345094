import { HashRouter } from "react-router-dom";
import "./Challenges.css";
const Challenges = () => {
  return (
    <div className="container">
      <div className="row" id="main-title">
        <h1>Challenges</h1>
      </div>
    </div>
  );
};

export default Challenges;
