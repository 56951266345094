import "./Workpackages.css";
const WorkPackages = () => {
  return (
    <div className="container ">
      <div className="row" id="main-title">
        <h1>Work Packages</h1>
      </div>
      <div className="row">
        <p id="workpackage-main-intro">
          CAREUP work plan is classified into various of work packages which are
          responsible for different activities throughout the project.
        </p>
      </div>
      <div className="row" id="workpackage-title">
        <h3>
          WP1: End-user challenges, scenarios and co-design of service concepts
        </h3>
      </div>
      <div className="row">
        <div className="col">
          <p id="workpackage-introduction">
            The objective is to identify end-user needs and goals and specify
            system requirements, identify interventions to be implemented in the
            multicomponent action plan, develop IC measures in PH domains and to
            specify system functionalities, services and scenarios of platform usage.
            <br />
            <br />
              <h6><u>Deliverables</u></h6>
              <ul>               
              <li>D1.1 End-user challenges, goals and platform requirements <i class="fas fa-lock"></i></li>
              <li>D1.2 Functionalities, service concepts and scenarios <i class="fas fa-lock"></i></li>
              <li>D1.3 Care plan components <i class="fas fa-lock"></i></li>
              <li>D1.4 Intrinsic Capacity measures in Positive Health domains <i class="fas fa-lock"></i></li>
              <li>D1.5 a,b. Ethics requirements, privacy, security and GDPR compliance <i class="fas fa-lock"></i></li>               
            </ul>
          </p>                     
        </div>
      </div>
      <br />
      <div className="row" id="workpackage-title">
        {" "}
        <h3>WP2: Platform design, implementation and integration</h3>
      </div>
      <div className="row">
        <div className="col">
          <p id="workpackage-introduction">
            The objectives are to design, implement, test (laboratory testing)
            and tune the fully integrated CAREUP platform. Tuning of the
            platform will be a constant activity based on the continuous
            feedback from WP3.
            <br />
            <br />
              <h6><u>Deliverables</u></h6>
              <ul>               
              <li>D2.1 CAREUP platform architecture <i class="fas fa-lock"></i></li>
              <li>D2.2 Development of the new platform components <i class="fas fa-lock"></i></li>
              <li>D2.3 AI engine for Intrinsic Capacity measures evaluation <i class="fas fa-lock"></i></li>
              <li>D2.4a Report on integration of the basic platform version <i class="fas fa-lock"></i></li>
              <li>D2.4b Report on integration, tuning and optimization <i class="fas fa-lock"></i></li>              
            </ul>
          </p>
        </div>
      </div>
      <br />
      <div className="row" id="workpackage-title">
        <h3>WP3: Pilots and platform validation</h3>
      </div>
      <div className="row">
        <div className="col">
          <p id="workpackage-introduction">
            The aim of this workpackage is to organize field trials, test,
            optimize and validate the developed platform components and the
            integrated platform with primary and secondary users, ensuring the
            ongoing participation of all stakeholders and with utmost emphasis
            on ethical and legal guidelines.
            <br />
            <br />
              <h6><u>Deliverables</u></h6>
              <ul>               
              <li>D3.1 End-user classification and pilots protocol <i class="fas fa-lock"></i></li>
              <li>D3.2 CAREUP platform new component tests <i class="fas fa-lock"></i></li>
              <li>D3.3 Report on integrated platform field trials and obtained results</li>              
            </ul>
          </p>
        </div>
      </div>
      <br />
      <div className="row" id="workpackage-title">
        <h3>WP4: Business model design, dissemination and exploitation</h3>
      </div>
      <div className="row">
        <div className="col">
          <p id="workpackage-introduction">
            This is another important workpackage that aim to bring the project
            results to the market quickly (1-2 yrs) by designing a competitive
            business strategy (model, plan, etc), involving stakeholders and
            dissemination. WP4 also aims to develop a lobbying attitude with
            stakeholders and specialized groups, large scale dissemination to
            scientific circles and the general public.
            <br />
            <br />
              <h6><u>Deliverables</u></h6>
              <ul>               
              <li>D4.1a Intermediate business plan and business model <i class="fas fa-lock"></i></li>
              <li>D4.1b Exploitation plan <i class="fas fa-lock"></i></li>
              <li>D4.1c Final business plan and business model <i class="fas fa-lock"></i></li>
              <li>D4.2 Creation, strengthening and connection of Healthy Ageing ecosystems <i class="fas fa-lock"></i></li>
              <li>D4.3a,b Reports on stakeholder concerns and demonstrator sessions <i class="fas fa-lock"></i></li>
              <li>D4.4 Scientific dissemination report</li>
              <li>D4.5a,b Report on IPR management <i class="fas fa-lock"></i></li>
            </ul>
          </p>
        </div>
      </div>
      <br />
      <div className="row" id="workpackage-title">
        <h3>WP5: Project Management</h3>
      </div>
      <div className="row">
        <div className="col">
          <p id="workpackage-introduction">
            Implement an effective management strategy to achieve all goals
            within the given time and proposed budget. The management activities
            will be lead by the CO (EXYS) who will leverage previous experience
            acquired through the participation as Project Coordinator of several
            AAL projects.
            <br />
            <br />
              <h6><u>Deliverables</u></h6>
              <ul>               
              <li>D5.1 Internal Communication Infrastructures <i class="fas fa-lock"></i></li>
              <li>D5.2a,b,c Calendar year report <i class="fas fa-lock"></i></li>
              <li>D5.3 Quality Assurance Plan <i class="fas fa-lock"></i></li>
              <li>D5.4 Mid-term review questionnaire <i class="fas fa-lock"></i></li>
              <li>D5.5 Final project report</li>
            </ul>
          </p>
        </div>
      </div>      
    </div>
  );
};

export default WorkPackages;
