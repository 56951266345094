import "./Press.css";
import { Link, NavLink } from "react-router-dom";

const PressFr = () => {
  return (
    <div className="container">
      <div className="row" id="main-title">
        <h1>Press Release</h1>
      </div>
      <div className="row languages">
        <h6>
          <button>
            <Link to="/press">EN</Link>
          </button>
          |
          <button id="IT">
            <NavLink to="/press-de">DE</NavLink>
          </button>
          |
          <button>
            <NavLink to="/press-pol">POL</NavLink>
          </button>
          |
          <button>
            <NavLink to="/press-it">IT</NavLink>
          </button>
          |
          <button>
            <NavLink to="/press-ro">RO</NavLink>
          </button>
        </h6>
      </div>

      <div className="row" id="press">
        <h1>
          Plateforme de soins intégrés basée sur le suivi de la capacité
          intrinsèque des personnes âgées pour une santé inclusive (CAREUP)
        </h1>
        <h6>Communiqué de presse/ 30 juin 2022</h6>
        <p>
          Le projet CAREUP, coordonné par ECLEXYS Sagl, étudie comment mettre en
          œuvre "l'approche inclusive a la santé et aux soins (prévention de la
          santé, préservation de la santé physique et mentale, participation
          sociale)" prévu par le CALL AAD 2021 "en tenant compte des besoins
          spécifiques des utilisateurs finaux". Les principaux utilisateurs
          finaux, les adultes âgés de plus de 65 ans, se plaignent souvent d'une
          baisse de leur niveau d'énergie, même en l'absence d'une maladie
          cliniquement manifeste (déclin des capacités et perte de réserves ou
          de robustesse) et ont du mal à trouver un sens à leur vie et à se
          fixer des objectifs. Nous pensons que ces aspects sont dus à l'absence
          d'une approche de coach de vie appropriée. Le projet CAREUP propose
          une solution basée sur les technologies de l'information et de la
          communication (TIC) pour améliorer continuellement la qualité de vie
          des personnes âgées en suivant un cadre de parcours de vie et en
          s'inspirant du concept de "santé positive" (PH, Positiv Health) et du
          concept de " vieillissement sain " (HA) de l'OMS. La solution CAREUP
          permet aux personnes âgées d'adhérer au concept PH/HA en surveillant
          elles-mêmes ses éléments clés : La capacité fonctionnelle (FA,
          Functional Ability) et la capacité intrinsèque (IC, Intrinsic
          Capacity). La capacité fonctionnelle comprend la capacité intrinsèque
          de l'individu et inclut toutes les capacités mentales, sociales,
          cognitives et physiques qu'une personne peut utiliser, y compris sa
          capacité à marcher, à penser, à sentir, à interagir, à réfléchir, à
          voir, à entendre et à se souvenir.
        </p>
        <br />
        <p>
          Pour atteindre les objectifs susmentionnés, le projet propose une
          approche intégrée et centrée sur la personne pour concevoir et mettre
          en œuvre une plateforme TIC capable de surveiller la CI et la FA des
          individus en relation avec leurs environnements spécifiques afin de
          compenser leurs déclins dus au vieillissement par le développement
          d'un plan de soins personnalisé et sur mesure avec des interventions
          multicomposantes. La plateforme CAREUP ne nécessite presque aucune
          action de la part de l'utilisateur. L'interaction humaine est combinée
          à la technologie informatique.
        </p>
        <br />
        <p>
          La plateforme est construite à faible coût et est ouverte aux
          extensions avec de nouveaux capteurs, questionnaires et jeux. Pour les
          utilisateurs finaux secondaires (soignants formels et informels), le
          besoin principal que CAREUP évaluera est la proposition d'un modèle de
          prestation de soins approprié/global conduisant à une approche
          intégrée des soins tout au long de la vie de la personne, qui passe de
          la guérison de la maladie à l'optimisation de la vie de la personne.
          Le problème principal pour les utilisateurs finaux secondaires sera le
          temps et les efforts nécessaires pour installer et entretenir la
          plateforme et pour former les utilisateurs finaux à son utilisation.
          Pour ce faire, la plateforme est co-créée avec les utilisateurs
          primaires et secondaires, informels et formels (médecins, travailleurs
          sociaux, psychologues...) pour réaliser notre proposition de vente
          unique. Pour les utilisateurs finaux tertiaires (parties prenantes),
          la solution CAREUP répondra à leur besoin de favoriser un écosystème
          de vieillissement sain, y compris des soins de santé abordables et des
          opportunités commerciales de style de vie sain, ainsi que la
          rentabilité des solutions proposées.
        </p>
        <p>
          Enfin, les partenaires du consortium du projet CAREUP (lien vers le
          consortium) sont bien équilibrés en termes de répartition
          géographique: 9 partenaires de 5 pays. Le consortium se compose de
          trois catégories de partenaires: une université et un centre de R&D,
          un utilisateur final et un centre de R&D, deux utilisateurs finaux et
          5 PME.
        </p>
        <p>
          Pour de plus amples informations, veuillez consulter (
          <Link to="/">CAREUP</Link>)
        </p>
      </div>
    </div>
  );
};

export default PressFr;
